import React from 'react';
import EmailConsultation from "../../contexts/emailConsultation/components/Form/EmailConsultation";
import SEO from "../../components/common/SEO/SEO";
import {graphql} from "gatsby";


const ConsultationEmailPage = ({data}) => {
  const {stripePrice} = data;
  return (
    <>
      <SEO
        robots={"index follow"}
        title={"Consulta floral express"}
        description={"Este espacio es para ofrecer consultas florales por escrito, para que puedas expresarte de manera escrita y recibir la selección floral personalizada en tu email en un plazo de 24h."}
        canonical={"constulta-escrita"}
      />
      <div className={"l-email-consultation"}>
        <div className="container">
          <h1>Consulta floral express</h1>
          <p>¡Hola! Soy María, fundadora de AlmaySer y Practitioner acreditada por el Bach Centre UK.
            Desde muy pequeña he sido una gran aficionada a la lectura, me encanta ponerle toda mi atención
            a las palabras que tengo delante y concentrarme para extraer todo el significado posible.</p>
          <p>
            Así que, he dedicado este espacio para ofrecer consultas florales por escrito,
            para que puedas expresarte de manera escrita y <b>recibir
            la selección floral personalizada en tu email en un plazo de 24h.</b>
          </p>
          <p>
            Para ello, te voy a hacer unas preguntas, que encontrarás a continuación, dónde podrás expresar todo lo que
            quieras. <b>Yo personalmente recibiré tus respuestas y te leeré con atención,</b> analizaré tus palabras
            detenidamente hasta extraer toda la información necesaria para hacer la selección de las Flores de Bach que
            mejor te puedan ayudar en tu situación.
            .<br/>
            Toda la información será confidencial y no será almacenada.
          </p>
          <p>
            En el caso de que con las respuestas no obtuviera suficiente información como para hacer una selección
            floral
            idónea, me pondré en contacto directo contigo para preguntarte y así seleccionar la fórmula floral que más
            beneficios te aporte.
          </p>
          <p>
            Este servicio, además de estar dedicado a las personas que desean realizar una Consulta Floral y obtener una
            respuesta pronta, está dedicado a las personas que desean expresarse a través de las palabras escritas,
            porque
            <b> cuando escribimos nos inspiramos de una forma especial.</b>

          </p>
          <EmailConsultation/>
          <p className={"text-rignt"} style={{lineHeight: "1em"}}>*Precio {stripePrice.unit_amount/100}€ <br/> <small>IVA incluido</small> </p>
        </div>
      </div>
    </>
  )
}

export default ConsultationEmailPage

export const query = graphql`
    query GetUnitPriceConsultationEmail ($id: String! ) {
        stripePrice(id: {eq: $id }) {
            unit_amount
        }
    }
`
