import "./email-consultation.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useState } from "react";

import getStripe from "../../../shared/infrastructure/stripe/stripejs";
import { MetadataEmailConsultation } from "../../../shop/domain/metadata-session";
import { ProductsTypes } from "../../../shop/domain/products-types";
import { QuestionsConstulation } from "../../../shop/domain/questions-constulation";

const EmailConsultation: React.FC = () => {
  const [is_loading, setLoading] = useState(false);

  // function getPrice(): number {
  //   // allPrices.nodes.forEach((node) => {
  //   //   if (node.id === process.env.GATSBY_STRIPE_KEY_EMAIL_CONSULTATION) {
  //   //     return node.unit_amount / 100;
  //   //   }
  //   // });
  // }

  const handlerSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    const body: MetadataEmailConsultation = {
      description: "",
      name: e.target.name.value,
      answer_a: e.target.answer_a.value,
      answer_b: e.target.answer_b.value,
      answer_c: e.target.answer_c.value,
      answer_d: e.target.answer_d.value,
      slug: "consulta-escrita",
      type: ProductsTypes.Email_consultation,
    };
    const response = await fetch("/.netlify/functions/create-session", {
      method: "POST",
      body: JSON.stringify({
        ...body,
      }),
    }).then((response) => response.json());
    const stripe = await getStripe(process.env.GATSBY_STRIPE_PUBLISHABLE);
    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    });
    if (error) {
      setLoading(false);
    }
  };

  return (
    <section className={"o-email-consultation"}>
      <div>
        <form className={"m-form"} onSubmit={handlerSubmit}>
          <label htmlFor={"name"}>
            ¿Cómo te llamas? ¿Cómo te gusta que te llamen?
          </label>
          <input id={"name"} placeholder={"nombre"} type={"text"} />
          <label htmlFor={"answer_a"}>{QuestionsConstulation.Question_a}</label>
          <textarea id={"answer_a"} maxLength={500} required />
          <label htmlFor={"answer_b"}>{QuestionsConstulation.Question_b}</label>
          <textarea id={"answer_b"} maxLength={500} required />
          <label htmlFor={"answer_c"}>{QuestionsConstulation.Question_c}</label>
          <textarea id={"answer_c"} maxLength={500} required />
          <label htmlFor={"answer_d"}>{QuestionsConstulation.Question_d}</label>
          <textarea id={"answer_d"} maxLength={500} required />
          <label htmlFor={"email"}>
            ¿A qué email quieres que te envie la respuesta con la fórmula floral
            de la selección de las Flores de Bach?{" "}
          </label>
          <input id={"email"} type={"email"} required />
          <div className={"a-private"}>
            <label htmlFor="politica">
              {" "}
              Acepto la{" "}
              <a
                href="/legal/politica-de-privacidad/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                política de privacidad
              </a>{" "}
            </label>
            <input
              type="checkbox"
              name="politica"
              id="politica"
              value="acepta-politica"
              required
            />
          </div>
          {/*<label>**precio {getPrice()}</label>*/}
          <button className={"a-button"} type={"submit"}>
            {!is_loading && <>Pagar y enviar</>}
            {is_loading && (
              <>
                Preparando pago <span className="a-spinner" />
              </>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default EmailConsultation;
