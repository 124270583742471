/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe } from '@stripe/stripe-js'

let stripePromise
const getStripe = (keyPublishable) => {
  if (!stripePromise) {
    stripePromise = loadStripe(keyPublishable)
  }
  return stripePromise
}

export default getStripe